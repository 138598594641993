import React from 'react'
import {Router} from '@reach/router'

import Frontend from '@fullstakk/fms-frontend-gatsby'

import ScreenHome from '../app/screen/Home'
import Service from '../lib/utility/Service'

/**
 * Encapsulates the application pages of the frontend.
 */
export default class App extends React.Component {
    /**
     * Renders the page.
     *
     * @returns {JSX.Element} Rendered page
     */
    render() {
        return (
            <Router>
                <Frontend type='Auth' service={{ Auth: Service.instance('Auth') }} path='/app/auth'/>
                <Frontend type='PrivateRoute' service={{
                    App: Service.instance('App'),
                    Auth: Service.instance('Auth')
                }} path='/app/home' component={ScreenHome}/>
            </Router>
        )
    }
}