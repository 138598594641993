import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'

function SEO({description, lang, meta, title, schema, service}) {
    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            app
            title
            description
            author
            siteUrl
            tracking {
              ga
            }
          }
        }
      }
    `
    )

    const metaTitle = title || site.siteMetadata.title
    const metaDescription = description || site.siteMetadata.description
    const titleTemplate = (metaTitle === site.siteMetadata.title) ? metaTitle : `%s | ${site.siteMetadata.app}`
    const microdata = schema || {
        "@context": "http://schema.org",
        "@type": "WebSite",
        "name": "Marketing Suite",
        "about": "Digital marketing tools",
        "url": "https://ms-fsm.web.app/",
        "image": "https://ms-fsm.web.app/facebook-ms.png",
        "creator": {
            "@type": "Organization",
            "name": "Fullstakk Marketing",
            "legalName": "Fullstakk Marketing AS",
            "url": "https://www.fullstakk.no/"
        },
        "hasPart": [{
            "@type": "WebSite",
            "name": "Qualifornia",
            "about": "Paid search tool",
            "url": "https://qualifornia-fsm.web.app/",
            "image": "https://qualifornia-fsm.web.app/facebook-qualifornia.png",
            "creator": {
                "@type": "Organization",
                "name": "Fullstakk Marketing",
                "legalName": "Fullstakk Marketing AS",
                "url": "https://www.fullstakk.no/"
            }
        }, {
            "@type": "WebSite",
            "name": "Ads Report",
            "about": "Paid search tool",
            "url": "https://adsreport-fsm.web.app/",
            "image": "https://adsreport-fsm.web.app/facebook-adsreport.png",
            "creator": {
                "@type": "Organization",
                "name": "Fullstakk Marketing",
                "legalName": "Fullstakk Marketing AS",
                "url": "https://www.fullstakk.no/"
            }
        }, {
            "@type": "WebSite",
            "name": "Trend Heat",
            "about": "SEO trend tool",
            "url": "https://trendheat-fsm.web.app/",
            "image": "https://trendheat-fsm.web.app/facebook-trendheat.png",
            "creator": {
                "@type": "Organization",
                "name": "Fullstakk Marketing",
                "legalName": "Fullstakk Marketing AS",
                "url": "https://www.fullstakk.no/"
            }
        }, {
            "@type": "WebSite",
            "name": "Shopping Alert",
            "about": "SEO trend tool",
            "url": "https://ctr-benchmark-fsm.web.app/",
            "image": "https://ctr-benchmark-fsm.web.app/facebook-ctr-benchmark.png",
            "creator": {
                "@type": "Organization",
                "name": "Fullstakk Marketing",
                "legalName": "Fullstakk Marketing AS",
                "url": "https://www.fullstakk.no/"
            }
        }]
    }

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={metaTitle}
            titleTemplate={titleTemplate}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
        >
            <script async src={'https://www.googletagmanager.com/gtag/js?id=' + site.siteMetadata.tracking.ga}></script>
            <script async src='https://www.google-analytics.com/analytics.js'></script>
            <script>{service.Ga.track(site.siteMetadata.tracking.ga)}</script>
            <script type="application/ld+json">
                {JSON.stringify(microdata)}
            </script>
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string//.isRequired,
}

export default SEO
