import React from 'react'

import Block from '@fullstakk/fms-frontend-block'
import Loader from '@fullstakk/fms-frontend-loader'
import Theme from '@fullstakk/fms-frontend-theme'
import {OObject} from '@fullstakk/fms-object'

import Seo from './Seo'

/**
 * Encapsulates any screen displaying asynchronous data.
 */
export default class DataScreen extends React.Component {
  /**
   * Initializes the component.
   *
   * @param Object props Properties of the component
   */
  constructor(props) {
    super(props)

    this.state = {
      page: {
        title: '',
        className: {
          main: []
        }
      },
      data: null,
      dataSource: DataScreen.defaultDataSource(),
      dataParam: {},
      dataLoaded: false
    }
  }

  /**
   * Renders the screen.
   */
  render() {
    const App = this.props.service.App
    if (!App.hasData()) {
      App.signOut()
    }

    return this.renderData()
  }

  /**
   * Renders the data.
   */
  renderData() {
    const App = this.props.service.App
    const Auth = this.props.service.Auth
    if (this.hasData()) {
      return (
        <Theme theme='HeaderMainFooter'
               user={Auth.isLoggedIn() ? Auth.getUser() : null}
               home={{link: App.getHomePageUrl(), title: 'Go back to Fullstakk Marketing Suite'}}
               login={{link: App.getLoginPageUrl(), title: 'Sign in'}}
               onLogOut={() => App.signOut()}
               mainClassName={this.state.page.className.main}
        >
          <Seo title={this.state.page.title}/>
          {this.renderMainContent()}
        </Theme>
      )
    }

    if (this.hasError()) {
      return (
        <Theme theme='HeaderMainFooter'
               user={Auth.isLoggedIn() ? Auth.getUser() : null}
               home={{link: App.getHomePageUrl(), title: 'Go back to Fullstakk Marketing Suite'}}
               login={{link: App.getLoginPageUrl(), title: 'Sign in'}}
               onLogOut={() => App.signOut()}
               mainClassName={this.state.page.className.main}
        >
          <Seo title={this.state.page.title}/>
          {this.renderError()}
        </Theme>
      )
    }

    return (
      <Theme theme='HeaderMainFooter'
             user={Auth.isLoggedIn() ? Auth.getUser() : null}
             home={{link: App.getHomePageUrl(), title: 'Go back to Fullstakk Marketing Suite'}}
             login={{link: App.getLoginPageUrl(), title: 'Sign in'}}
             onLogOut={() => App.signOut()}
      >
        <Seo title={this.state.page.title}/>
        <Loader/>
      </Theme>
    )
  }

  /**
   * Renders the main content of the layout.
   */
  renderMainContent() {
    return null
  }

  /**
   * Renders the error block.
   */
  renderError() {
    return (
      <Block
        theme='Error'
        type={this.state.data.error.type}
        msg={(Object.getOwnPropertyNames(this.state.data.error.msg).length === 0) ? 'Not connected!' : this.state.data.error.msg} />
    )
  }

  /**
   * Loads data after the layout has been mounted.
   */
  componentDidMount() {
    this.loadData()
  }

  /**
   * Loads data after the layout has been updated.
   *
   * @param Object prevProps Previous values of the properties of the layout
   * @param Object prevState Previous values of the state of the layout
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.state.dataSource !== prevState.dataSource) || !OObject.equal(this.state.dataParam, prevState.dataParam)) {
      this.loadData()
    }
  }

  /**
   * Loads data from the current source.
   *
   * @return Boolean The process has succeed (true) or not (false)
   */
  loadData() {
  }

  /**
   * Processes after data has been loaded.
   *
   * @param Object Loaded data
   * @paran Object state State parameters to update
   * @paran function callback Optional callback function to execute
   * @paran Boolean saveDataSource Save data source into the application storage (true) or not (false)
   */
  onDataLoaded(data, state, callback, saveDataSource) {
    const App = this.props.service.App
    state = state || null
    saveDataSource = (saveDataSource === undefined) ? true : saveDataSource
    if (state.dataParam && (state.dataParam.token !== undefined)) {
      delete state.dataParam.token
    }

    if (data !== undefined) {
      state = (data === null) ? Object.assign({
        dataLoaded: true
      }, state) : Object.assign({
        data: data,
        dataLoaded: true
      }, state)

      this.setState(state)

      if (saveDataSource) {
        App.setData('dataSource', this.state.dataSource)
      }

      if (callback) {
        callback(state)
      }
    }
  }

  /**
   * Determines whether some data have been loaded or not.
   *
   * @return Boolean There are loaded data (true) or not (false)
   */
  hasData() {
    if (((typeof this.state.data) === 'object') && (this.state.data !== null)) {
      return !this.hasError()
    }

    return false
  }

  /**
   * Determines whether some data loading parameters not.
   *
   * @return Boolean There are data loading parameters (true) or not (false)
   */
  hasDataParam() {
    return (((typeof this.state.dataParam) === 'object') && Object.keys(this.state.dataParam).length)
  }

  /**
   * Determines whether some error has occurred during data loading or not.
   *
   * @return Boolean An error has occurred (true) or not (false)
   */
  hasError() {
    const App = this.props.service.App
      const Auth = this.props.service.Auth
    if (((typeof this.state.data) === 'object') && (this.state.data !== null)) {
      if (((typeof this.state.data.error) === 'object') && (this.state.data.error !== null)) {
        const authUser = Auth.getUser()
        if ((this.state.data.error.type === 'no-refresh-token') || (this.state.data.error.type === 'gads-oauth-token-revoked') || !authUser.accessToken) {
          return App.signOut()
        }

        return true
      }
    }

    return false
  }

  /**
   * Returns data loading parameters.
   *
   * @return Object Data loading parameters
   */
  loadDataParam() {
    return {}
  }

  /**
   * Checks the value of a data.
   *
   * @param String data Data to check
   * @param String defaultValue Default value considered as not usable
   * @return Boolean The data is valid (true) or not (false)
   */
  checkData(data, defaultValue) {
    defaultValue = defaultValue || 'none'
    return ((data !== undefined) && (data !== defaultValue))
  }

  /**
   * Returns default data source.
   *
   * @param String source The source from where data are collected from by default
   * @return Object Default data source
   * @static
   */
  static defaultDataSource(source) {
    return source || 'none'
  }

  /**
   * Returns default data container.
   *
   * @param String source The source from where data are collected from
   * @param String data Data collected from the source
   * @param Object param Optional parameters sent with data query
   * @return Object Default data container
   * @static
   */
  static defaultDataContainer(source, data, param) {
    return {
      source: source || 'none',
      data: data || null,
      param: param || null
    }
  }
}
