import React from 'react'

import Layout from '@fullstakk/fms-frontend-theme'

import Seo from '../../lib/components/Application/Seo'
import Service from '../../lib/utility/Service'

import BlockApp from '../components/Application/Block'

import CfgEnv from '../../../conf/env.json'

/**
 * Homepage screen.
 */
export default class Home extends React.Component {
    /**
     * Renders the main layout.
     */
    render() {
        const App = Service.instance('App')
        const authUser = Service.instance('Auth').getUser()

        //const arfUrl = App.getUrl('adsreport', '/app/auth/' + authUser.email)
        const thUrl = App.getUrl('trendheat')
        // const ctrUrl = App.getUrl('ctr', '/app/auth/' + authUser.email)
        const qfUrl = App.getUrl('qualifornia', '/app/auth/' + authUser.email)
        const shopUrl = App.getUrl('shop', '/app/auth/' + authUser.email)

        return (
            <Layout
                theme='HeaderMainFooterCentered'
                title={App.getName()}
                home={ { link: App.getHomePageUrl(), title: 'Go back to Fullstakk Marketing Suite'} }
                user={authUser}
                mainClassName='wrapper-flex'
                onLogOut={() => App.signOut() }
            >
                <Seo title={'Welcome ' + authUser.name + '!'} />
                <article className='text-center'>
                    <h1>Welcome {authUser.name}!</h1>
                    <p>Take an advantage for your web campaigns with the Fullstakk Marketing tools: SEO trends, global paid search scores...</p>
                    <div className='block-app-list'>
                        {this.renderBlockAuthApp('sea', 'Qualifornia', 'Paid search', qfUrl, 'Get the global scores of your campaigns, level by level: account, campaign, ad group & keyword', (<span>To use Qualifornia, <a href={CfgEnv.google.account} target='_blank' rel='noopener noreferrer'>create your Google account</a></span>))}
                        {/*this.renderBlockAuthApp('sea', 'Ads Report', 'Paid search', arfUrl, 'Report the performances of your Ads campaigns since the last weeks or months', (<span>To use Ads Report, <a href={CfgEnv.google.account} target='_blank' rel='noopener noreferrer'>create your Google account</a></span>))*/}
                        <BlockApp type='seo' title='Trend Heat' subTitle='SEO trends' description='See keyword indexes week by week from the 5 past years, with weekly & monthly scores' url={thUrl} />
                        {/* this.renderBlockAuthApp('seo', 'CTR Benchmark', 'SEO performance', ctrUrl, 'Discover the performance of your website in Google search result pages...', (<span>To use CTR Benchmark, <a href={CfgEnv.google.account} target='_blank' rel='noopener noreferrer'>create your Google account</a></span>)) */}
                        {this.renderBlockAuthApp('sea', 'Shopping Alert', 'Google Shopping', shopUrl, 'Track & correct fastly all issues about Google Shopping products', (<span>To use Shopping Alert, <a href={CfgEnv.google.account} target='_blank' rel='noopener noreferrer'>create your Google account</a></span>))}
                    </div>
                </article>
            </Layout>
        )
    }

    /**
     * Renders the block of an authorized application.
     *
     * @param string type Type of the application
     * @param string title Name of the application
     * @param string subTitle Category of the application
     * @param string url URL of the application
     * @param Object description Description of the application
     * @param Object message Message displayed explaining the user how to get an access to the application if he does not yet
     */
    renderBlockAuthApp(type, title, subTitle, url, description, message) {
        return (
            <BlockApp type={type} title={title} subTitle={subTitle} description={description} url={url} />
        )
        /*if(Service.instance('Auth').getUser().gadsId) {
            return (
                <BlockApp type={type} title={title} subTitle={subTitle} description={description} url={url} />
            )
        }

        return (
            <BlockApp type={type} title={title} subTitle={subTitle} description={description} msg={message} />
        )*/
    }
}
