import React from 'react'
import PropTypes from 'prop-types'

/**
 * Renders the private components.
 *
 * @param Component component Component to render
 * @param Object location Current address
 * @param Boolean noredirect Do no redirect (true) if user is not logged
 * @param Object rest The other properties of the component
 * @returns {JSX.Element|null} The rendered component
 * @constructor
 */
const PrivateRoute = ({ component: Component, location, noredirect, ...rest }) => {
    const App = rest.service.App
    const Auth = rest.service.Auth
    if (!Auth.isLoggedIn() && location.pathname !== '/app/auth' && !noredirect) {
        App.redirect()
        return null
    }

    return <Component {...rest} />
}

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired
}

export default PrivateRoute
