import React  from 'react'

/**
 * Application block.
 */
export default class Block extends React.Component {
    /**
     * Renders the component.
     */
    render() {
        return (
            <div className={'block-app block-' + this.props.type}>
                <h2>{this.props.title}</h2>
                <h3>{this.props.subTitle}</h3>
                <p className='block-app-desc'>{this.props.description}</p>
                {this.renderButton()}
            </div>
        )
    }

    /**
     * Renders the component.
     */
    renderButton() {
        if(this.props.url) {
            return (
                <p><a href={this.props.url} data-title={this.props.title} className='button' onClick={this.props.onClickButton}>{'Use ' + this.props.title}</a></p>
            )
        }

        return (
            <p className='block-app-msg'>{this.props.msg}</p>
        )
    }
}