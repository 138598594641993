import React from 'react'

import Layout from '@fullstakk/fms-frontend-theme'

/**
 * Encapsulates the authentication callback.
 */
export default class AuthScreen extends React.Component {
    /**
     * Renders the page.
     *
     * @returns {JSX.Element} Rendered page
     */
    render() {
        return <Layout type='Loader'/>
    }

    /**
     * Renders the main layout.
     */
    componentDidMount() {
        this.props.service.Auth.logInApp(this.props.gid)
    }
}
