import React from 'react'

import Auth from './lib/Auth'
import DataScreen from './lib/DataScreen'
import PrivateRoute from './lib/PrivateRoute'
import Seo from './lib/Seo'

/**
 * Main component.
 */
export default class Component extends React.Component {
  /**
   */
  render() {
    const type = this.props.type || 'DataScreen'

    switch (type) {
      case 'Auth':
        return (<Auth {...this.props} />)
      case 'DataScreen':
        return (<DataScreen {...this.props} />)
      case 'PrivateRoute':
        return (<PrivateRoute {...this.props} />)
      case 'Seo':
      default:
        return (<Seo {...this.props} />)
    }
  }
}
